<template>
   <v-container fluid>
      <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
      <modal-habilitar :objModalHabilitar="objModalHabilitar"></modal-habilitar>
      <modal-inhabilitar :objModalInhabilitar="objModalInhabilitar"></modal-inhabilitar>
      <v-row>
         <v-col cols="12">
            <v-card class elevation max-width>
               <v-card-title class="justify-center">GESTIONAR PRODUCTOS</v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <!-- Navegación -->
                     <div class="col-12">
                        <p>
                           <router-link
                              exact
                              style="text-decoration: none"
                              :to="{ name: 'inicioPanel' }"
                           >Inicio</router-link>/
                           <router-link
                              exact
                              style="text-decoration: none"
                              :to="{ name: 'lentesContactoPanel' }"
                           >Lentes de contacto</router-link>
                        </p>
                     </div>
                     <!-- Navegación -->

                     <!-- Botones -->
                     <v-col cols="12" class="text-right">
                        <v-btn
                           v-show="validarPermiso('productos.crear')"
                           :to="{ name: 'lentesContactoCrearPanel' }"
                           color="primary"
                           dark
                           large
                        >
                           <v-icon left large>mdi-plus-circle</v-icon>NUEVO
                           LENTES DE CONTACTO
                        </v-btn>
                     </v-col>
                     <!-- Botones -->

                     <!-- Filtros -->
                     <v-col md="2" cols="12">
                        <v-select
                           v-model="filtros.cantidadRegistros"
                           item-text="texto"
                           item-value="valor"
                           :items="resources.cantidadRegistros"
                           label="Cantidad de registros"
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           dense
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col md="2" cols="12">
                        <v-select
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtros.estado"
                           clearable
                           dense
                           item-text="texto"
                           item-value="valor"
                           :items="resources.estado"
                           label="Estado"
                           outlined
                        ></v-select>
                     </v-col>
                     
                     <v-col md="4" cols="12">
                        <v-text-field
                           @keyup.prevent.enter="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           @click:clear="
                              paginaActual = 1;
                              filtros.nombre = '';
                              listarRegistros();
                           "
                           @keyup.prevent.delete="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtros.nombre"
                           clearable
                           autocomplete="off"
                           outlined
                           dense
                           label="Nombre..."
                        ></v-text-field>
                     </v-col>

                     <v-col md="4" cols="12">
                        <v-btn class="my-0" color="info" elevation @click="filtrar()">
                           <v-icon>mdi-filter</v-icon>Filtrar
                        </v-btn>

                        <v-btn class="ml-2" title="Limpiar filtros" color="blue-grey" @click="limpiarFiltros()" >
                           <v-icon color="white" dark>mdi-refresh</v-icon>
                        </v-btn>

                        <v-btn class="ml-2" :loading="btnReporteExcel" @click="reporteExcel()">
                           <v-icon color="success">mdi-file-excel</v-icon>
                        </v-btn>
                     </v-col>
                     <!-- Filtros -->


                     <!-- Tabla -->
                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-simple-table dense fixed-header ref="tblListado">
                           <template v-slot:default>
                              <thead class="text-no-wrap text-center">
                                 <tr>
                                    <th> ACCIONES </th>
                                    <th> CÓDIGO </th>
                                    <th> NOMBRE </th>
                                    <th> SUB NOMBRE </th>
                                    <th> MARCA </th>
                                    <th> TIPO DE LENTES </th>
                                    <th> PRECIO </th>
                                    <th> ESTADO </th>
                                 </tr>
                              </thead>
                              <tbody class="text-no-wrap">
                                 <tr v-for="item in arrRegistrosTabla" :key="item.idproducto">
                                    <td>
                                       <v-menu offset-y>
                                          <template v-slot:activator="{ on, attrs }">
                                             <v-btn
                                                small
                                                dark
                                                depressed
                                                color="cyan"
                                                v-bind="attrs"
                                                v-on="on"
                                             >
                                                <v-icon left>mdi-cursor-default-click</v-icon>Acciones
                                             </v-btn>
                                          </template>
                                          <v-list>

                                             <v-list-item :href="linkDetalle(item)" target="__blank">
                                                <v-icon>mdi-eye</v-icon> Ver lentes de contacto
                                             </v-list-item>
                                             


                                             <v-list-item v-show=" validarPermiso( 'productos.editar' ) "
                                                :to="{
                                                   name: 'lentesContactoEditarPanel',
                                                   params: { idproducto: item.idproducto, },
                                                }"
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-pencil</v-icon>Editar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-if="item.estado" v-show=" validarPermiso( 'productos.inhabilitar' ) "
                                                @click="
                                                   objModalInhabilitar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-close</v-icon>Inhabilitar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-else v-show=" validarPermiso( 'productos.habilitar' ) "
                                                @click="
                                                   objModalHabilitar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-check</v-icon>Habilitar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-show=" validarPermiso( 'productos.eliminar' ) "
                                                @click="
                                                   objModalEliminar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-trash-can</v-icon>Eliminar
                                                </v-list-item-title>
                                             </v-list-item>
                                          </v-list>
                                       </v-menu>
                                    </td>
                                    <td v-text="item.codigo"></td>
                                    <td v-text="item.nombre"></td>
                                    <td v-text="item.sub_nombre"></td>
                                    <td v-text="item.idmarca ? item.marca.nombre : ''"></td>
                                    <td>
                                       <ul v-if="item.lc_tipos.length > 0">
                                          <li
                                             v-for="(item,idx) in item.lc_tipos"
                                             :key="idx"
                                             >
                                             {{ item.nombre }}
                                          </li>
                                       </ul>
                                    </td>
                                    <td v-text="item.precio"></td>
                                    <td>
                                       <v-chip
                                          small
                                          class="ma-2"
                                          color="green"
                                          text-color="white"
                                          v-if="item.estado"
                                       >Habilitado</v-chip>
                                       <v-chip
                                          small
                                          class="ma-2"
                                          color="red"
                                          text-color="white"
                                          v-else
                                       >Inhabilitado</v-chip>
                                    </td>
                                    
                                 </tr>
                              </tbody>
                           </template>
                        </v-simple-table>

                        <p>
                           Mostrando del registro
                           <span v-text="primerRegistro"></span> al
                           <span v-text="ultimoRegistro"></span> de un total de
                           <span v-text="totalRegistro"></span> filas
                        </p>
                     </v-col>

                     <v-col cols="12 text-center" v-show="loaderListado">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>

                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-pagination
                           circle
                           v-model="paginaActual"
                           :length="cantidadproductos"
                           :total-visible="7"
                           @input="listarRegistros()"
                           :disabled="loaderListado"
                        ></v-pagination>
                     </v-col>
                     <!-- Tabla -->

                     <!-- En caso de no haber registros -->
                     <v-col cols="12" v-show="alertRegistroNoEcontrado">
                        <v-alert
                           type="error"
                           dense
                           outlined
                           class="text-center"
                        >NO HAY REGISTROS DISPONIBLES PARA MOSTRAR</v-alert>
                     </v-col>
                     <!-- En caso de no haber registros -->
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import modalEliminar from "./Eliminar";
import modalHabilitar from "./Habilitar";
import modalInhabilitar from "./Inhabilitar";

export default {
   components: {
      modalEliminar,
      modalHabilitar,
      modalInhabilitar,
   },
   data() {
      return {
         resources: {
            cantidadRegistros: [
               {
                  texto: 10,
                  valor: 10,
               },
               {
                  texto: 30,
                  valor: 30,
               },
               {
                  texto: 50,
                  valor: 50,
               },
               {
                  texto: 100,
                  valor: 100,
               },
               {
                  texto: 500,
                  valor: 500,
               },
            ],
            estado: [
               {
                  texto: "Habilitado",
                  valor: 1,
               },
               {
                  texto: "Inhabilitado",
                  valor: 0,
               },
            ],
         },
         filtros: {
            cantidadRegistros: 10,
            estado: "",
            nombre: "",
         },
         loaderListado: false,
         alertRegistroNoEcontrado: false,
         arrRegistrosTabla: [],
         paginaActual: 1,
         cantidadproductos: 1,
         primerRegistro: 0,
         ultimoRegistro: 0,
         totalRegistro: 0,
         objModalEliminar: null,
         objModalHabilitar: null,
         objModalInhabilitar: null,
         btnReporteExcel: false,
      };
   },

   methods: {
      listarRegistros() {
         this.arrRegistrosTabla = [];
         this.loaderListado = true;
         this.alertRegistroNoEcontrado = false;

         this.axios({
            method: "GET",
            url: "api/panel/lentes-de-contacto",
            params: {
               cantidadRegistros: this.filtros.cantidadRegistros,
               paginaActual: this.paginaActual,
               estado: this.filtros.estado,
               nombre: this.filtros.nombre,
            },
         })
            .then((response) => {
               let listado = response.data.data;

               this.arrRegistrosTabla = listado.data;
               this.paginaActual      = listado.current_page;
               this.cantidadproductos = listado.last_page;
               this.primerRegistro    = listado.from;
               this.ultimoRegistro    = listado.to;
               this.totalRegistro     = listado.total;

               this.alertRegistroNoEcontrado = this.arrRegistrosTabla.length === 0;
            })
            .catch((error) => {
               console.log(error.response);
            })
            .finally(() => {
               this.loaderListado = false;
               this.$vuetify.goTo(this.$refs.tblListado);
            });
      },

      reporteExcel() {
         window.open(
            this.apiUrl(
               "api/panel/lentes-de-contacto/reporteExcel?filtros.estado=" +
                  this.filtros.estado +
                  "&filtros.nombre=" +
                  this.filtros.nombre
            ),
            "_self"
         );

      },

      filtrar() {
         this.paginaActual = 1;
         this.listarRegistros();
      },

      limpiarFiltros() {
         this.filtros.cantidadRegistros = 10;
         this.paginaActual = 1;
         this.filtros.estado = "";
         this.filtros.nombre = "";
         this.listarRegistros();
      },
      linkDetalle(item) {
         return process.env.VUE_APP_URL_WEB+'lentes-de-contacto/'+item.idproducto+'/'+item.slug;
      }
   },

   created() {
      if (!this.validarPermiso("productos.listado")) {
         this.$toasted.error("Usted no tiene permisos para esta sección", { icon: "mdi-close", });
         this.$router.push({ name: "inicioPanel", });
      }

      this.listarRegistros();
   },
};
</script>

<style scoped>
.margin-cabecera-tabla {
   margin-right: 60px !important;
   margin-left: 60px !important;
}
</style>
